import { useState } from "react";
import img from "../img/photo.png";
import { Stat } from "./Player/Stat";
import { Request } from "./Player/Request";

import data from '../player.json';

const Player = () => {

    const [tab,setTab] = useState(0);

    return (
        <div className="d-block my-3">
        <div className="my-3 d-flex gap-5 align-items-center">
          <div className="photo">
            <img src={img} />
          </div>
          <div className="col">
            <h3 className="fw-bold">Імануель Ель Кравчук</h3>
            <div className="d-flex mt-4 gap-5">
              <div>
                <div className="label">Дата народження</div>
                <div className="fw-bold">21 серпня 2005</div>
              </div>
              <div>
                <div className="label">Амплуа</div>
                <div className="fw-bold">Півзахисник</div>
              </div>
              <div>
                <div className="label">Стать</div>
                <div className="fw-bold">Чоловіча</div>
              </div>
              <div>
                <div className="label">Зріст</div>
                <div className="fw-bold">170см</div>
              </div>
              <div>
                <div className="label">Вага</div>
                <div className="fw-bold">70кг</div>
              </div>
            </div>
          </div>
        </div>

        <div className="tabs">
          <div className={`tabs-item ${tab == 0 ? 'active':''}`} onClick={() => setTab(0)}>Персональна статистика</div>
          <div className={`tabs-item ${tab == 1 ? 'active':''}`} onClick={() => setTab(1)}>Заявки</div>
        </div>

        {
            {
              0: <Stat data={data.stat} />,
              1: <Request data={data.request} />,
            }[tab]
          }
        
      </div>
    )
}

export {Player}