const Request = ({data}) =>{
    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Команда/Тип трансферу</th>
                        <th>Асоціація</th>
                        <th>Федерація</th>
                        <th>Сезон</th>
                        <th>Вік</th>
                        <th>Дата заявки</th>
                        <th>Дата від заявки</th>
                        <th>Ознака</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length
                        ?data.map((item,key) => (
                            <tr key={key}>
                                <td>{item.team}</td>
                                <td>{item.asoc}</td>
                                <td>{item.fed}</td>
                                <td>{item.sezon}</td>
                                <td className="text-nowrap">{item.age}</td>
                                <td>{item.date1}</td>
                                <td>{item.date2}</td>
                                <td>{item.sign}</td>
                            </tr>
                        ))
                        :<tr><td colSpan="100%" className="text-center">Нічого не знайдено</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export {Request}