import { Link } from "react-router-dom"

const Main = () => {

    localStorage.removeItem("filter");
    
    return (
        <div className="d-block my-3">
            <h2 className="mb-3">Оберіть лігу</h2>
            <div className="d-flex gap-3 w-100">
                <Link to="/stat/elit" className="liga col">
                    Еліт ліга
                </Link>
                <Link to="/stat/height" className="liga col">
                    Вища ліга
                </Link>
                <Link to="/stat/first" className="liga col">
                    Перша ліга
                </Link>
            </div>
        </div>
    )
}

export {Main}