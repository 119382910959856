import { Link, useParams } from "react-router-dom";

const Tur = ({data}) =>{

    // data.sort((a, b) => a.age - b.age);

    // desc
    const teams = data.teams.sort((a, b) => b.points - a.points);
    // asc
    //const teams = data[0].teams.sort((a, b) => a.points - b.points);

    const {liga} = useParams();

    return (
        <table className="table">
          <thead>
            <tr>
              <th>Місце</th>
              <th>Команда</th>
              <th>Ігри</th>
              <th>Пенальті</th>
              <th>Перемоги</th>
              <th>Нічиї</th>
              <th>Поразки</th>
              <th>Мʼячі</th>
              <th>Очки</th>
            </tr>
          </thead>
          <tbody id="content">
            {teams.length
                ?teams.map((item,key) => (
                    <tr key={key}>
                        <td>{key+1}</td>
                        <td><Link to={`/stat/${liga}/club/0`} className="link">{item.team}</Link></td>
                        <td>{item.games}</td>
                        <td>-</td>
                        <td>{item.win}</td>
                        <td>{item.draw}</td>
                        <td>{item.defeat}</td>
                        <td>{item.ball}</td>
                        <td>{item.points}</td>
                    </tr>
                ))
                :<tr><td colSpan="100%" className="text-center">Нічого не знайдено</td></tr>
            }
          </tbody>
        </table>
    )
}

export {Tur}