import Layout from "./Page/Layout";
import { Main } from "./Page/Main";
import { Player } from "./Page/Player";
import { Stat } from "./Page/Stat";

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Team } from "./Page/Team";

function App() {

  return (
      <Router>
        <Routes>
          <Route path="/" element={<Layout/>} >
            <Route index element={<Main/>} />
            <Route path="/stat/:liga" element={<Stat/>} />
            <Route path="/stat/:liga/club/:clubId" element={<Team />} />
            <Route path="/stat/:liga/club/:clubId/player/:playerId" element={<Player />} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
