import { Link, useParams } from "react-router-dom"

const Calendar = ({data}) =>{

    const {liga} = useParams();

    return (
        <table className="table">
          <thead>
            <tr>
              <th>Коло</th>
              <th>Тур</th>
              <th>Дата проведення</th>
              <th>Команда господар</th>
              <th>Рахунок</th>
              <th>Команда гість</th>
            </tr>
          </thead>
          <tbody id="content">
            {data.calendar.length
                ?data.calendar.map((item,key) => (
                    <tr key={key}>
                        <td>{item.step}</td>
                        <td>{item.name}</td>
                        <td>{item.date}</td>
                        <td><Link to={`/stat/${liga}/club/${item.team1Id}`} className="link">{item.team1}</Link></td>
                        <td>{item.score}</td>
                        <td><Link to={`/stat/${liga}/club/${item.team1Id}`} className="link">{item.team1}</Link></td>
                    </tr>
                ))
                :<tr><td colSpan="100%" className="text-center">Нічого не знайдено</td></tr>
            }
          </tbody>
        </table>
    )
}

export {Calendar}