import { Link, Navigate, useParams } from "react-router-dom";

import data from '../team.json';

const Team = () => {

    const {liga,clubId} = useParams();

    return (
        <div className="d-block my-3">
            <h3>Динамо (Київ)</h3>
            <table className="table _round">
                <thead>
                    <tr>
                        <th>Гравець</th>
                        <th>Дата народження</th>
                        <th>К-сть ігор</th>
                        <th>Голи</th>
                        <th>ЖК</th>
                        <th>к-ть хвилин</th>
                        <th>Амплуа</th>
                        <th>Зріст</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length
                        ?data.map((item,key) => (
                            <tr key={key}>
                                <td><Link to={`/stat/${liga}/club/${item.teamId}/player/${item.id}`} className="link">{item.name}</Link></td>
                                <td>{item.bd}</td>
                                <td>{item.games}</td>
                                <td>{item.goals}</td>
                                <td>{item.gk}</td>
                                <td>{item.time}</td>
                                <td>{item.role}</td>
                                <td>{item.height}</td>
                            </tr>
                        ))
                        :<tr><td colSpan="100%" className="text-center">Нічого не знайдено</td></tr>
                    }
                </tbody>
            </table>
      </div>
    )
}

export {Team}