import { useEffect, useRef, useState } from "react";
import { Calendar } from "./Stat/Calendar";
import { Players } from "./Stat/Players";
import { Tur } from "./Stat/Tur";

import stat from "../stat.json";
import { useParams } from "react-router-dom";

const def = {
  sezon: null,
  team: null,
  group: null,
};

const Stat = () => {

  const [data,setData] = useState(stat);

  const [tab, setTab] = useState(0);

  const [sezon, setSezon] = useState(["2020-2021", "2021-2022", "2021-2023"]);

  const [team, setTeam] = useState(["U15", "U16"]);

  const [group, setGroup] = useState(["Група 1", "Група 2"]);

  const [filter, setFilter] = useState(
    localStorage.getItem("filter")
      ? JSON.parse(localStorage.getItem("filter"))
      : def
  );

  useEffect(() => {
    localStorage.setItem("filter", JSON.stringify(filter));
    setData(null);
    setTimeout(()=>setData(stat),1000);
  }, [filter]);

  const { name } = useParams();

  const setSearch = (prop) => {};

  return (
    <>
      <div className="d-flex gap-3 mb-3 flex-wrap">
        <Select
          name="Сезон"
          option={sezon}
          filter={filter}
          setFilter={setFilter}
          value={"sezon"}
        />
        <Select
          name="Команда"
          option={team}
          filter={filter}
          setFilter={setFilter}
          value={"team"}
        />
        <Select
          name="Група"
          option={group}
          filter={filter}
          setFilter={setFilter}
          value={"group"}
        />
        <div>
          <div className="button">Пошук</div>
        </div>
      </div>
      <div className="tabs">
        <div
          className={`tabs-item ${tab == 0 ? "active" : ""}`}
          onClick={() => setTab(0)}
        >
          <b>Турнірна таблиця</b>
        </div>
        <div
          className={`tabs-item ${tab == 1 ? "active" : ""}`}
          onClick={() => setTab(1)}
        >
          Календар ігор
        </div>
        <div
          className={`tabs-item ${tab == 2 ? "active" : ""}`}
          onClick={() => setTab(2)}
        >
          Заявлені гравці
        </div>
      </div>
      {data?
        {
          0: <Tur data={data} />,
          1: <Calendar data={data} />,
          2: <Players data={data} />,
        }[tab]
        :<div className="loader-container"><div className="loader"></div></div>
      }
    </>
  );
};

const Select = ({ name, option, value, setFilter, filter }) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  const data = filter[value];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setdata = (index) => {
    filter[value] = index;
    setFilter({ ...filter });
    setShow(false);
  };

  return (
    <div>
      <div>
        <input 
          className="select" 
          onClick={() => setShow((prev) => !prev)} 
          value={data !== null ? option[data]:""} 
          placeholder={name}
          readOnly
        />
      </div>
      {show ? (
        <div className="select-container" ref={dropdownRef}>
          <div className="select-dropdown">
            <div className="overflow overflow-x px-1 h-100">
              {option.map((item, key) => (
                <div
                  key={key}
                  onClick={() => setdata(key)}
                  className={`select-dropdown-item mb-1 ${
                    filter[value] === key ? "_active" : ""
                  }`}
                  // onClick={() =>
                  //   setSearch((prev) => ({ ...prev, sezon: "2022-2023" }))
                  // }
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { Stat };
