import { Link, Navigate, useLocation, useParams } from "react-router-dom";

const leagueNames = {
    elit: 'Еліт ліга',
    height: 'Вища ліга',
    first: 'Перша ліга'
};
  
const clubNames = [
    'Динамо (Київ)'
];

const playerName = [
    'Імануель Ель Кравчук'
];

const Nav = () =>{
    
    const location = useLocation();
    const { liga, clubId, playerId } = useParams(); // Отримуємо параметри з URL
    const pathnames = location.pathname.split('/').filter((x) => x);

    if (!leagueNames.hasOwnProperty(liga) && liga) {
        return <Navigate to="/" />;
    }

    if (parseInt(clubId) !== 0 && clubId) {
        return <Navigate to="/" />;
    }

    if (parseInt(playerId) !== 0 && playerId) {
        return <Navigate to="/" />;
    }

    return (
        <div className="d-block my-3">
            <div className="navi">
                <Link to="/" className="navi-item">Головна</Link>
                {pathnames.map((value, index) => {
                    let to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;
        
                    let name;
                    switch (value) {
                    case 'stat':
                        name = `${leagueNames[liga]}`;
                        to = `/stat/${liga}`;
                        break;
                    case 'club':
                        name = `${clubNames[clubId]}` || '';
                        to += `/${clubId}`;
                        break;
                    case 'player':
                        name = `${playerName[playerId]}` || '';
                        break;
                    default:
                        to = null;
                        break;
                    }

                    return to?<Link to={to} className="navi-item" key={index}>{name}</Link>:<></>
                })}
            </div>
        </div>
    )
}

export {Nav}