import { Link, useParams } from "react-router-dom";

const Players = ({data}) => {

    const {liga} = useParams();

    return (
        <table className="table">
          <thead>
            <tr>
              <th>Клуб</th>
              <th>Гравець</th>
              <th>Дата народження</th>
              <th>Амплуа</th>
              <th>К-сть ігор</th>
              <th>к-ть хвилин</th>
              <th>Голи</th>
            </tr>
          </thead>
          <tbody id="content3">
            {data.players.length
                ?data.players.map((item,index) => (
                    <tr key={index}>
                      <td><Link to={`/stat/${liga}/club/${item.teamId}`} className="link">{item.team}</Link></td>
                      <td><Link to={`/stat/${liga}/club/${item.teamId}/player/${item.id}`} className="link">{item.name}</Link></td>
                      <td>{item.bd}</td>
                      <td>{item.role}</td>
                      <td>{item.games}</td>
                      <td>{item.time}</td>
                      <td>{item.goal}</td>
                    </tr>
                ))
              :<tr><td colSpan="100%" className="text-center">Нічого не знайдено</td></tr>
            }
          </tbody>
        </table>
    )
}

export {Players}