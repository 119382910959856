import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import {Nav} from './Nav';

function Layout() {
  return (
    <div className='h-100 d-flex flex-column p-4'>
      <Nav/>
      <main className='flex-grow-1'>
        <Outlet />
      </main>
      <footer>
        <p>© 2024 duflu.org.ua</p>
      </footer>
    </div>
  );
}

export default Layout;
