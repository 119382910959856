const Stat = ({data}) => {
    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Турнір</th>
                        <th>Ігри</th>
                        <th>Осн</th>
                        <th>Зам</th>
                        <th>ГЗ</th>
                        <th>Зап</th>
                        <th>Хв</th>
                        <th>Голи</th>
                        <th>Пен</th>
                        <th>Авт</th>
                        <th>ЖК</th>
                        <th>ЧК</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length
                        ?data.map((item,key) => (
                            <tr key={key}>
                                <td>{item.game}</td>
                                {item.data.map((num, index)=>
                                    <td key={index}>{num}</td>
                                )}
                            </tr>
                        ))
                        :<tr><td colSpan="100%" className="text-center">Нічого не знайдено</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export {Stat}